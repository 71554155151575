<div class="container-fluid h-100" *ngIf="article">
  <div class="h-100 d-flex flex-wrap-reverse">
    <div class="left-content" >
      <div [ngClass]="{'load-position': show}" >
        <app-loader [show]="show" [inView]="true" [inSearch]="inSearch" [noResult]="searchNotFound"  [topChoice]="'top-loader-article'"></app-loader>
        <div class="content">
          <div class="view-content">
              <span class="article-title line-clamp">{{article.title | uppercase}}</span>
                <div class="search-bar-container" [ngClass]="{'sticky-position' :!show}">
                  <app-control-f
                    (onPrev)="controlFService.goToSelected($event)"
                    (onNext)="controlFService.goToSelected($event)"
                    (onSearch)="article.text && controlFService.prev_find($event, articleViewElement,article.text)"
                    [totalMatches]="controlFService.totalMatches"
                    [documentLoaded]="true">
                  </app-control-f>
              </div>
              <app-image-carousel-smart *ngIf="article" [article]="article" ></app-image-carousel-smart>
              <div [innerHTML]="impactersStyle"></div>
              <div class="text text-justify" data-cy="full-text-article-view"
                   [innerHTML]="article.text ? (article.text | highlight: controlFService.inputSearch | reference: article.impacters): ''"
                   #articleView
              >
              </div>
              <div class="ID-article-0CtgAin" *ngIf="documentProviderId.length>0">ID article : {{documentProviderId}}</div>
         </div>
        </div>
      </div>
    </div>
    <div data-cy="article-view-info-section" class="info-section">
      <app-metadata
        [documentType]="'article'"
        [source]="article.source"
        [territories]="article.territories"
        [publicationDate]="article.publicationDate"></app-metadata>
      <app-saving-panel [document]="{
                          id: article.id,
                          type: type,
                          title: article.title,
                          snippet: article.snippet,
                          bookmarkPaths: article.bookmarkPaths
      }" (bookmarkPathsUpdated)="bookmarkPathsUpdated(article.id, $event)"></app-saving-panel>
      <app-expandable-share-panel
        class="sharing-panel"
        [document]="documentToBeShared"
        [withExportPanel]="false"
      />
      <app-entities-accordion [article]="article" class="flex-fill overflow-hidden">
      </app-entities-accordion>
    </div>
  </div>
</div>
